<template>
  <div>
    <div class="content-top">
      <p class="font-bold mt-3 text-lg">{{ project_name }} - Student Registrations</p>
      <el-button class="create_btn" type="danger" @click="btnCreate">New Registration</el-button>
    </div>
    <div style="padding: 20px;">
      <el-table :data="dataList" style="font-size: 12px;box-sizing: border-box;" empty-text="No Data"
                :highlight-current-row="false" :highlight-selection-row="false"
                :header-cell-class-name="'custom-head-cell'"
                :cell-style="tableCellstyle" :row-class-name="'custom-row-bg'">
        <!--            <el-table-column prop="index" type="index" label="Index" width="100px"></el-table-column>-->
        <el-table-column prop="application_project" label="Student">
          <template slot-scope="scope">
            {{scope.row.student.student_givenName_pinyin+ ' ' +scope.row.student.student_lastName_pinyin}}
          </template>
        </el-table-column>
        <el-table-column prop="student.student_email" label="Email"></el-table-column>
        <el-table-column prop="student.student_graduation" label="Grad Year" width="120px"></el-table-column>
        <el-table-column prop="application_status" label="Status" width="120px">
          <template slot-scope="scope">
            {{scope.row.application_status ==='无需缴费'?'Paid':'Pending Payment'}}
          </template>
        </el-table-column>
        <el-table-column label="Operation">
          <template slot-scope="scope">
            <el-button v-if="scope.row.application_status==='待缴费'" type="danger" @click="btnRemove(scope.row)">Remove</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :visible.sync="isShowDialog"
        title="New Registration"
        width="860px"
    >
      <div>
        <el-form :inline="true" ref="form" :rules="rules" :model="formData" label-width="180px">
          <el-form-item label="Given Name (Native)" prop="givenName">
            <el-input v-model="formData.givenName" placeholder="Please input" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Last Name (Native)" prop="lastName">
            <el-input v-model="formData.lastName" placeholder="Please input" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Given Name (EN)" prop="givenName_pinyin">
            <el-input v-model="formData.givenName_pinyin" placeholder="Please input" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Last Name (EN)" prop="lastName_pinyin">
            <el-input v-model="formData.lastName_pinyin" placeholder="Please input" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="formData.email" placeholder="Please input" style="width: 220px;"></el-input>
          </el-form-item>
          <el-form-item label="Year of Graduation" prop="graduation">
            <el-select v-model="formData.graduation" clearable placeholder="Please select" style="width: 220px;">
              <el-option
                  v-for="item in yearOpt"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div style="width: 100%; text-align: center; margin-top: 30px">
          <el-button size="medium" @click="isShowDialog=false">Cancel</el-button>
          <el-button type="primary" size="medium" @click="saveInfo(formData)">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  addAccountAdvisor, createApplicationByProjectAndAccount,
  getStudentAppsByAccount, removeApplication
} from '../../api/eae'
import {
  getUserId,
  getTargetType, getAccountId
} from '../../utils/store'

export default ({
  name: 'index',
  data() {
    return {
      project_id: this.$route.query.project_id,
      project_code: this.$route.query.project_code,
      project_name: this.$route.query.project_name,
      dataList: [],
      year: null,
      yearOpt: [],
      isShowDialog: false,
      formData: {
        project_code: '',
        account_id: '',
        lastName: '',
        givenName: '',
        lastName_pinyin: '',
        givenName_pinyin: '',
        graduation: '',
        email: '',
        student_guardian_first_email: ''
      },
      rules: {
        givenName: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        lastName: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        account_nameEN: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        lastName_pinyin: [
          { required: true, message: "Please input", trigger: "change" },
        ],
        givenName_pinyin: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
        graduation: [
          { required: true, message: "Please choose", trigger: "change" },
        ],
        email: [
          {required: true, message: 'Please input the email', trigger: 'blur'},
          {type: 'email', message: 'Please input the correct email address', trigger: ['blur', 'change']}
        ]
      },
      isShowCDialog: false,
    }
  },
  mounted() {
    const currentYear = new Date().getFullYear()
    this.yearOpt = []
    for(let i=0;i<6;i++){
      this.yearOpt.push(currentYear+i)
    }
    this.fetchData()
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getStudentAppsByAccount(getAccountId(), this.project_id).then((res) => {
        this.dataList = res.data
        loading.close()
      })
    },
    tableCellstyle() {
      return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
    },
    btnCreate(){
      this.isShowDialog = true
    },
    saveInfo(data){
      this.formData.account_id = getAccountId()
      this.formData.project_code = this.project_code
      this.$refs.form.validate((val) => {
        if (val) {
          createApplicationByProjectAndAccount(this.formData).then((res) => {
            if(res.data._id.$id){
              this.fetchData()
            }else{
              this.$message.error("Registration Failed");
            }
            /*this.$router.replace({
              path: '/form/success',
              query: {
                id: res.data._id.$id,
                project: 'registration'
              }
            })*/
            this.isShowDialog = false
          });
        }
      });
    },
    btnRemove(data) {
      this.$confirm(
          'Are you sure you want to remove '+data.student.student_givenName_pinyin+ ' ' +data.student.student_lastName_pinyin+' from the '+ data.application_project +'？',
          "Remove Students",
          {
            type: "warning",
            confirmButtonText: "Confirm",
          }
      ).then(() => {
        removeApplication(data._id.$id).then((res) => {
          this.$message.success('Registration Removed Successfully')
          this.fetchData()
        })
      }).catch(() => {});
    }
  }
})
</script>
<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
  background: #FAE3E4;
}

/deep/ .el-table tbody .cell {
  font-family: Ubuntu-Light;
}

/deep/ .el-table .custom-head-cell {
  background: var(--c-primary);
  color: white;
  text-align: center;
  border: 1px solid white;
  box-sizing: border-box;
  font-family: Ubuntu-Bold;
}

.content-top {
  position: relative;
  margin: 0 20px;
}

.create_btn {
  position: absolute;
  top: 0;
  right: 0;
}
</style>